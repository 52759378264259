import { CloudinaryImageSmall, ImageApi } from '@beatgig/api/image'

import { Image } from '@beatgig/api-services'
import cloudinary from 'cloudinary-tiny-js'
import { CloudinaryImage } from './types'
import { ImageSmall } from '@beatgig/api-services/image'

export const CLOUDINARY_CLOUD_NAME = `dn29xlaeh`

const client = cloudinary({ cloudName: CLOUDINARY_CLOUD_NAME })

// function Cloudinary(image: Image, options: CloudinaryImage): string
// function Cloudinary(config: { publicId: string }, options: CloudinaryImage): string

function Cloudinary(
  image?: Image | ImageSmall | CloudinaryImageSmall,
  // we want to enforce size, not "width", so this change is to break the types.
  { quality = 75, width = 600, size, ...options }: CloudinaryImage = {}
): string | undefined {
  if (!image) return

  if (!image.public_cloudinary_id && ImageApi.hasUrl(image)) {
    return image.url
  }

  // we want to shrink the smaller of the two values
  let sizeKey = 'width'
  if (ImageApi.hasUrl(image) && image.height < image.width) {
    sizeKey = 'height'
  }

  return client(image.public_cloudinary_id, {
    quality,
    ...options,
    // TODO change width to "maxSize"?
    [sizeKey]: width ? Number(width).toFixed(0) : undefined,
  }) as string
  // if (!url) return ''

  // let final = url
  // if (options.width) {
  //   final = url.replace(`/upload/v`, `/upload/w_${options.width}/v`)
  // }
  // return final
  // const id = url?.split('')
  // return client(url, options)
}

type Cloudinary = (url: string | Image, options: CloudinaryImage) => string

export default Cloudinary
