import { Image } from '@beatgig/api-services'
import { ImageSmall, ImagesService } from '@beatgig/api-services/image'

export type CloudinaryImageSmall = {
  public_cloudinary_id: string
}

export class ImageApi {
  // https://stackoverflow.com/questions/59082540/react-native-base64-image-upload-to-firebase-storage
  static async upload(base64: string) {
    return ImagesService.uploadImage({
      requestBody: {
        image_bytes: base64,
      },
    })
  }
  static ARTIST_PROFILE_IMAGE_SIZE = 640
  static ARTIST_SMALL_PROFILE_IMAGE_SIZE = 150

  static hasUrl(
    image: Image | ImageSmall | CloudinaryImageSmall
  ): image is Image | ImageSmall {
    return !!(image && (image as Image).url)
  }
}
